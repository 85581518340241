<template>
  <Layout>
    <mainSection />
  </Layout>
</template>

<script setup>
import { onMounted } from "vue";
import mainSection from "@/modules/widget/components/home/main-section";
import Layout from "@/modules/widget/components/home/Layout/Index";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";

const { getContacts } = useWidgetConfig();

onMounted(() => getContacts());
</script>
